
.main-visual {
    ::v-deep {
        .swiper-container {
            overflow: visible;
        }
        .swiper-control {
            top: auto;
            bottom: 26px;
            -webkit-transform: translate(-50%, 0);
            -ms-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            padding: 0 12px;
        }
        .swiper-pagination-bullets {
            position: relative;
            bottom: auto;
            left: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 200px;
        }
        .swiper-pagination-bullet {
            position: relative;
            flex: 1 1 auto;
            width: 100%;
            height: 6px;
            margin: 0 2px;
            border-radius: 0;
            opacity: 1;
            background-color: rgba(255, 255, 255, 0.4);
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                display: block;
                width: 100%;
                height: 100%;
                transform: scaleX(0);
                transform-origin: left;
                background-color: #fff;
            }
            &-active {
                &::before {
                    transform: scaleX(1);
                    transition: all ease-out 5s;
                    transition-delay: 1.2s;
                }
            }
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .main-visual {
        ::v-deep {
            .swiper-control {
                bottom: 40px;
            }
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
    .main-visual {
        ::v-deep {
            .swiper-control {
                bottom: 50px;
                max-width: var(--container-md);
                justify-content: end;
                padding-right: 50px;
            }
            .swiper-pagination {
                transform: none;
            }
            .swiper-pagination-bullets {
                width: 264px;
            }
            .swiper-pagination-bullet {
                margin: 0 4px;
            }
        }
    }
}
@media (min-width: 1500px) {
    .main-visual {
        ::v-deep {
            .swiper-control {
                bottom: 100px;
            }
        }
    }
}

/* main-visual */
.main-visual {
    margin: auto;
    &__img {
        width: 100%;
        padding-top: calc(1 / 1 * 100%);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .main-visual {
        max-width: 1680px;
        &__img {
            width: 100%;
            padding-top: calc(760 / 1680 * 100%);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
