
.side-gnb-wrap {
    &__right {
        position: fixed;
        top: calc(var(--header-body) + 30px);
        left: 50%;
        -webkit-transform: translate(-50%,0); -ms-transform: translate(-50%,0); transform: translate(-50%,0);
        max-width: calc(var(--container-lg) + 12px * 2);
        width: 100%;
        text-align: right;
        padding: 0 12px;
    }
}
