
.footer {
    font-family: var(--font-spoqa);
    color: #666;
    background-color: #202020;
    .container {
        max-width: calc(var(--container-lg) + 12px * 2);
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
.footer-link {
    display: flex;
    flex-wrap: wrap;
    margin: -4px -10px;
    li {
        position: relative;
        padding: 4px 10px;
        &::before {
            display: none;
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            -webkit-transform: translate(0, calc(-50% + 1px));
            -ms-transform: translate(0, calc(-50% + 1px));
            transform: translate(0, calc(-50% + 1px));
            width: 1px;
            height: 8px;
            background-color: #393939;
        }
        &:first-child::before {
            display: none;
        }
        a {
            display: block;
            font-size: 1.4rem;
            font-weight: 500;
            line-height: 1.2;
            color: #666;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .footer-link {
        margin: 0 -14px;
        li {
            padding: 0 14px;
            ::before {
                display: block;
                height: 10px;
            }
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}

.footer-info {
    font-size: 1.2rem;
    margin: -2px -6px;
    overflow: auto;
    &::after {
        content: "";
        clear: both;
        display: table;
    }
    li {
        display: inline-block;
        float: left;
        font-size: 1.2rem;
        padding: 2px 6px;
        &.clear {
            clear: left;
        }
        > span {
            margin-right: 4px;
            color: #fff;
        }
    }
    &__tit {
        font-weight: 500;
        color: #fff;
        margin-bottom: 2px;
    }
}
@media (min-width: 768px) {
    .footer-info {
        li {
            font-size: 1.4rem;
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}

.copyright {
    font-size: 1.2rem;
    color: #666;
}
::v-deep {
    .familysite {
        width: 160px;
        font-size: 1.4rem;
        border-radius: 0;
        .v-input__slot {
            height: 30px !important;
        }
        .v-text-field--outlined legend {
            border: none;
        }
        &,
        input::placeholder,
        .v-select__selection--comma,
        .mdi:before {
            color: #666 !important;
        }
        input::placeholder {
            font-weight: 500;
        }
        &.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
            color: #393939 !important;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .copyright {
        font-size: 1.4rem;
    }
    ::v-deep {
        .familysite {
            .v-input__slot {
                height: 30px !important;
            }
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
