
.main-section {
    padding: 60px 0;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    &--visual {
        padding: 0 !important;
    }
}
@media (min-width: 576px) {
    .main-section {
        &--visual {
            padding: 0 12.5% !important;
        }
    }
}
@media (min-width: 768px) {
    .main-section {
        padding: 80px 0;
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
    .main-section {
        padding: 140px 0;
        &--visual {
            padding: 0 12.5% 0 0 !important;
        }
    }
}
