
.main-video-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    &__inner {
        width: 100%;
        padding: 0 30px;
        overflow: hidden;
    }
}
.main-video {
    width: 100%;
    margin: auto;
    &__inner {
        position: relative;
        width: 100%;
        transform: scale(0.9);
        -webkit-transition: transform ease-out 0.2s;
        -ms-transition: transform ease-out 0.2s;
        transition: transform ease-out 0.2s;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            display: block;
            width: 100%;
            height: 100%;
            opacity: 1;
            visibility: visible;
            background-color: rgba(0, 0, 0, 0.5);
            -webkit-transition: all ease-out 0.2s;
            -ms-transition: all ease-out 0.2s;
            transition: all ease-out 0.2s;
        }
    }
    ::v-deep {
        .swiper-container {
            overflow: visible;
        }
        .swiper-slide {
            &-active {
                .main-video__inner {
                    transform: scale(1);
                    &::before {
                        opacity: 0;
                        visibility: hidden;
                    }
                }
            }
        }
        .swiper-button-prev,
        .swiper-button-next {
            width: 30px;
            height: 30px;
            color: rgba(0, 0, 0, 0.54);
            font: normal normal normal 24px/1 "Material Design Icons";
            margin-top: 0;
            &::after {
                font-size: 20px;
            }
            &:hover {
                color: var(--v-primary-base);
            }
        }
        .swiper-button-prev {
            left: 0;
            right: auto;
            transform: translate(-100%, -50%);
        }
        .swiper-button-next {
            left: auto;
            right: 0;
            transform: translate(100%, -50%);
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .main-video-wrap {
        &__inner {
            padding: 0 60px;
        }
    }
    .main-video {
        ::v-deep {
            .swiper-button-prev,
            .swiper-button-next {
                width: 60px;
                height: 60px;
                &::after {
                    font-size: 40px;
                }
            }
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
    .main-video-wrap {
        &__inner {
            width: 3412px;
            padding: 0;
        }
    }
    .main-video {
        width: 1084px;
        ::v-deep {
            .swiper-button-prev {
                left: -4px;
                right: auto;
            }
            .swiper-button-next {
                left: auto;
                right: -4px;
            }
        }
    }
}
@media (min-width: 1600px) {
    .main-video {
        ::v-deep {
            .swiper-button-prev {
                left: -30px;
            }
            .swiper-button-next {
                right: -30px;
            }
        }
    }
}
