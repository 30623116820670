
.banner {
    position: relative;
    margin: -20px -12px 0;
    overflow: hidden;
    &__inner {
        padding: 20px 12px;
    }
    ::v-deep {
        .swiper-container {
            padding: 20px 12px;
            overflow: visible;
        }
        .swiper-control {
            top: auto;
            bottom: 0px;
            -webkit-transform: translate(-50%, 0);
            -ms-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
            max-width: var(--container);
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            padding: 0;
        }
        .swiper-pagination-bullets {
            position: relative;
            bottom: auto;
            left: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 210px;
        }
        .swiper-pagination-bullet {
            position: relative;
            flex: 1 1 auto;
            width: 100%;
            height: 6px;
            margin: 0 2px;
            border-radius: 0;
            opacity: 1;
            background-color: rgba(0, 0, 0, 0.2);
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                display: block;
                width: 100%;
                height: 100%;
                transform: scaleX(0);
                transform-origin: left;
                background-color: #222;
            }
            &-active {
                &::before {
                    transform: scaleX(1);
                    transition: all ease-out 5s;
                    transition-delay: 0.2s;
                }
            }
        }
    }
    &__img {
        width: 100%;
        padding-top: calc(496 / 330 * 100%);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        -webkit-transition: all ease-out 0.4s;
        -ms-transition: all ease-out 0.4s;
        transition: all ease-out 0.4s;
        &:hover {
            box-shadow: var(--shadow);
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .banner {
        margin: -20px -20px 0;
        overflow: hidden;
        ::v-deep {
            .swiper-container {
                padding: 20px 20px 60px;
            }
            .swiper-pagination {
                transform: none;
            }
            .swiper-pagination-bullet {
                margin: 0 4px;
            }
        }
    }
}
@media (min-width: 1200px) {
}

.banner {
    .row {
        > [class*="col-"] {
            opacity: 0;
            transform: translateY(25%);
        }
    }
    &.aos-animate {
        .row {
            > [class*="col-"] {
                animation: fade 0.8s ease-in-out forwards;
                &:nth-child(1) {
                    animation-delay: 0.4s !important;
                }
                &:nth-child(2) {
                    animation-delay: 0.8s !important;
                }
                &:nth-child(3) {
                    animation-delay: 1.2s !important;
                }
                &:nth-child(4) {
                    animation-delay: 1.6s !important;
                }
            }
        }
    }
}
@keyframes fade {
    0% {
        opacity: 0;
        transform: translateY(25%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
